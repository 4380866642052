import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../context/Context";
import {evaluate} from 'mathjs'
import OneInsited from "./OneInsited";
import OneHiddenNom from "./OneHiddenNom";
import IcoTrash from "./IcoTrash";
import IcoClone from "./IcoClone";
import IcoArr from "./IcoArr";
import IcoPlus from "./IcoPlus";

const OneElement = (props) => {
    // states and vars
    const {Q_, Qs_, Lg_, isSysDat, isPrices, sumReady, setPopStock} = useContext(AuthContext)
    const [paramsVars] = useState({
        a : 1,
        b : 1,
        c : 1,
        d : 1,
        e : 1,
        f : 1,
        g : 1,
        h : 1,
        i : 1,
        j : 1,
        k : 1,
        l : 1,
        m : 1,
        n : 1,
        o : 1,
        p : 1,
        q : 1,
        r : 1,
        s : 1,
        t : 1,
        u : 1,
        v : 1,
        w : 1,
        x : 1,
        y : 1,
        z : 1,
        aa : 1,
        ba : 1,
        ca : 1,
        da : 1,
        ea : 1,
        fa : 1,
        ga : 1,
        ha : 1,
        ia : 1,
        ja : 1,
        ka : 1,
        la : 1,
        ma : 1,
        na : 1,
        oa : 1,
        pa : 1,
        qa : 1,
        ra : 1,
        sa : 1,
        ta : 1,
        ua : 1,
        va : 1,
        wa : 1,
        xa : 1,
        ya : 1,
        za : 1,
        ab : 1,
        bb : 1,
        cb : 1,
        db : 1,
        eb : 1,
        fb : 1,
        gb : 1,
        hb : 1,
        ib : 1,
        jb : 1,
        kb : 1,
        lb : 1,
        mb : 1,
        nb : 1,
        ob : 1,
        pb : 1,
        qb : 1,
        rb : 1,
        sb : 1,
        tb : 1,
        ub : 1,
        vb : 1,
        wb : 1,
        xb : 1,
        yb : 1,
        zb : 1,
        ac : 1,
        bc : 1,
        cc : 1,
        dc : 1,
        ec : 1,
        fc : 1,
        gc : 1,
        hc : 1,
        ic : 1,
        jc : 1,
        kc : 1,
        lc : 1,
        mc : 1,
        nc : 1,
        oc : 1,
        pc : 1,
        qc : 1,
        rc : 1,
        sc : 1,
        tc : 1,
        uc : 1,
        vc : 1,
        wc : 1,
        xc : 1,
        yc : 1,
        zc : 1,
        ad : 1,
        bd : 1,
        cd : 1,
        dd : 1,
        ed : 1,
        fd : 1,
        gd : 1,
        hd : 1,
        id : 1,
        jd : 1,
        kd : 1,
        ld : 1,
        md : 1,
        nd : 1,
        od : 1,
        pd : 1,
        qd : 1,
        rd : 1,
        sd : 1,
        td : 1,
        ud : 1,
        vd : 1,
        wd : 1,
        xd : 1,
        yd : 1,
        zd : 1,
        ae : 1,
        be : 1,
        ce : 1,
        de : 1,
        ee : 1,
        fe : 1,
        ge : 1,
        he : 1,
        ie : 1,
        je : 1,
        ke : 1,
        le : 1,
        me : 1,
        ne : 1,
        oe : 1,
        pe : 1,
        qe : 1,
        re : 1,
        se : 1,
        te : 1,
        ue : 1,
        ve : 1,
        we : 1,
        xe : 1,
        ye : 1,
        ze : 1,
        af : 1,
        bf : 1,
        cf : 1,
        df : 1,
        ef : 1,
        ff : 1,
        gf : 1,
        hf : 1,
        if : 1,
        jf : 1,
        kf : 1,
        lf : 1,
        mf : 1,
        nf : 1,
        of : 1,
        pf : 1,
        qf : 1,
        rf : 1,
        sf : 1,
        tf : 1,
        uf : 1,
        vf : 1,
        wf : 1,
        xf : 1,
        yf : 1,
        zf : 1,
        ag : 1,
        bg : 1,
        cg : 1,
        dg : 1,
        eg : 1,
        fg : 1,
        gg : 1,
        hg : 1,
        ig : 1,
        jg : 1,
        kg : 1,
        lg : 1,
        mg : 1,
        ng : 1,
        og : 1,
        pg : 1,
        qg : 1,
        rg : 1,
        sg : 1,
        tg : 1,
        ug : 1,
        vg : 1,
        wg : 1,
        xg : 1,
        yg : 1,
        zg : 1,
        ah : 1,
        bh : 1,
        ch : 1,
        dh : 1,
        eh : 1,
        fh : 1,
        gh : 1,
        hh : 1,
        ih : 1,
        jh : 1,
        kh : 1,
        lh : 1,
        mh : 1,
        nh : 1,
        oh : 1,
        ph : 1,
        qh : 1,
        rh : 1,
        sh : 1,
        th : 1,
        uh : 1,
        vh : 1,
        wh : 1,
        xh : 1,
        yh : 1,
        zh : 1,
        ai : 1,
        bi : 1,
        ci : 1,
        di : 1,
        ei : 1,
        fi : 1,
        gi : 1,
        hi : 1,
        ii : 1,
        ji : 1,
        ki : 1,
        li : 1,
        mi : 1,
        ni : 1,
        oi : 1,
        pi : 1,
        qi : 1,
        ri : 1,
        si : 1,
        ti : 1,
        ui : 1,
        vi : 1,
        wi : 1,
        xi : 1,
        yi : 1,
        zi : 1,
        aj : 1,
        bj : 1,
        cj : 1,
        dj : 1,
        ej : 1,
        fj : 1,
        gj : 1,
        hj : 1,
        ij : 1,
        jj : 1,
        kj : 1,
        lj : 1,
        mj : 1,
        nj : 1,
        oj : 1,
        pj : 1,
        qj : 1,
        rj : 1,
        sj : 1,
        tj : 1,
        uj : 1,
        vj : 1,
        wj : 1,
        xj : 1,
        yj : 1,
        zj : 1,
        ak : 1,
        bk : 1,
        ck : 1,
        dk : 1,
        ek : 1,
        fk : 1,
        gk : 1,
        hk : 1,
        ik : 1,
        jk : 1,
        kk : 1,
        lk : 1,
        mk : 1,
        nk : 1,
        ok : 1,
        pk : 1,
        qk : 1,
        rk : 1,
        sk : 1,
        tk : 1,
        uk : 1,
        vk : 1,
        wk : 1,
        xk : 1,
        yk : 1,
        zk : 1,
        al : 1,
        bl : 1,
        cl : 1,
        dl : 1,
        el : 1,
        fl : 1,
        gl : 1,
        hl : 1,
        il : 1,
        jl : 1,
        kl : 1,
        ll : 1,
        ml : 1,
        nl : 1,
        ol : 1,
        pl : 1,
        ql : 1,
        rl : 1,
        sl : 1,
        tl : 1,
        ul : 1,
        vl : 1,
        wl : 1,
        xl : 1,
        yl : 1,
        zl : 1,
        am : 1,
        bm : 1,
        cm : 1,
        dm : 1,
        em : 1,
        fm : 1,
        gm : 1,
        hm : 1,
        im : 1,
        jm : 1,
        km : 1,
        lm : 1,
        mm : 1,
        nm : 1,
        om : 1,
        pm : 1,
        qm : 1,
        rm : 1,
        sm : 1,
        tm : 1,
        um : 1,
        vm : 1,
        wm : 1,
        xm : 1,
        ym : 1,
        zm : 1,
        an : 1,
        bn : 1,
        cn : 1,
        dn : 1,
        en : 1,
        fn : 1,
        gn : 1,
        hn : 1,
        in : 1,
        jn : 1,
        kn : 1,
        ln : 1,
        mn : 1,
        nn : 1,
        on : 1,
        pn : 1,
        qn : 1,
        rn : 1,
        sn : 1,
        tn : 1,
        un : 1,
        vn : 1,
        wn : 1,
        xn : 1,
        yn : 1,
        zn : 1,
        ao : 1,
        bo : 1,
        co : 1,
        do : 1,
        eo : 1,
        fo : 1,
        go : 1,
        ho : 1,
        io : 1,
        jo : 1,
        ko : 1,
        lo : 1,
        mo : 1,
        no : 1,
        oo : 1,
        po : 1,
        qo : 1,
        ro : 1,
        so : 1,
        to : 1,
        uo : 1,
        vo : 1,
        wo : 1,
        xo : 1,
        yo : 1,
        zo : 1,
        ap : 1,
        bp : 1,
        cp : 1,
        dp : 1,
        ep : 1,
        fp : 1,
        gp : 1,
        hp : 1,
        ip : 1,
        jp : 1,
        kp : 1,
        lp : 1,
        mp : 1,
        np : 1,
        op : 1,
        pp : 1,
        qp : 1,
        rp : 1,
        sp : 1,
        tp : 1,
        up : 1,
        vp : 1,
        wp : 1,
        xp : 1,
        yp : 1,
        zp : 1,
        aq : 1,
        bq : 1,
        cq : 1,
        dq : 1,
        eq : 1,
        fq : 1,
        gq : 1,
        hq : 1,
        iq : 1,
        jq : 1,
        kq : 1,
        lq : 1,
        mq : 1,
        nq : 1,
        oq : 1,
        pq : 1,
        qq : 1,
        rq : 1,
        sq : 1,
        tq : 1,
        uq : 1,
        vq : 1,
        wq : 1,
        xq : 1,
        yq : 1,
        zq : 1,
        ar : 1,
        br : 1,
        cr : 1,
        dr : 1,
        er : 1,
        fr : 1,
        gr : 1,
        hr : 1,
        ir : 1,
        jr : 1,
        kr : 1,
        lr : 1,
        mr : 1,
        nr : 1,
        or : 1,
        pr : 1,
        qr : 1,
        rr : 1,
        sr : 1,
        tr : 1,
        ur : 1,
        vr : 1,
        wr : 1,
        xr : 1,
        yr : 1,
        zr : 1,
        as : 1,
        bs : 1,
        cs : 1,
        ds : 1,
        es : 1,
        fs : 1,
        gs : 1,
        hs : 1,
        is : 1,
        js : 1,
        ks : 1,
        ls : 1,
        ms : 1,
        ns : 1,
        os : 1,
        ps : 1,
        qs : 1,
        rs : 1,
        ss : 1,
        ts : 1,
        us : 1,
        vs : 1,
        ws : 1,
        xs : 1,
        ys : 1,
        zs : 1,
        at : 1,
        bt : 1,
        ct : 1,
        dt : 1,
        et : 1,
        ft : 1,
        gt : 1,
        ht : 1,
        it : 1,
        jt : 1,
        kt : 1,
        lt : 1,
        mt : 1,
        nt : 1,
        ot : 1,
        pt : 1,
        qt : 1,
        rt : 1,
        st : 1,
        tt : 1,
        ut : 1,
        vt : 1,
        wt : 1,
        xt : 1,
        yt : 1,
        zt : 1,
        au : 1,
        bu : 1,
        cu : 1,
        du : 1,
        eu : 1,
        fu : 1,
        gu : 1,
        hu : 1,
        iu : 1,
        ju : 1,
        ku : 1,
        lu : 1,
        mu : 1,
        nu : 1,
        ou : 1,
        pu : 1,
        qu : 1,
        ru : 1,
        su : 1,
        tu : 1,
        uu : 1,
        vu : 1,
        wu : 1,
        xu : 1,
        yu : 1,
        zu : 1,
        av : 1,
        bv : 1,
        cv : 1,
        dv : 1,
        ev : 1,
        fv : 1,
        gv : 1,
        hv : 1,
        iv : 1,
        jv : 1,
        kv : 1,
        lv : 1,
        mv : 1,
        nv : 1,
        ov : 1,
        pv : 1,
        qv : 1,
        rv : 1,
        sv : 1,
        tv : 1,
        uv : 1,
        vv : 1,
        wv : 1,
        xv : 1,
        yv : 1,
        zv : 1,
        aw : 1,
        bw : 1,
        cw : 1,
        dw : 1,
        ew : 1,
        fw : 1,
        gw : 1,
        hw : 1,
        iw : 1,
        jw : 1,
        kw : 1,
        lw : 1,
        mw : 1,
        nw : 1,
        ow : 1,
        pw : 1,
        qw : 1,
        rw : 1,
        sw : 1,
        tw : 1,
        uw : 1,
        vw : 1,
        ww : 1,
        xw : 1,
        yw : 1,
        zw : 1,
        ax : 1,
        bx : 1,
        cx : 1,
        dx : 1,
        ex : 1,
        fx : 1,
        gx : 1,
        hx : 1,
        ix : 1,
        jx : 1,
        kx : 1,
        lx : 1,
        mx : 1,
        nx : 1,
        ox : 1,
        px : 1,
        qx : 1,
        rx : 1,
        sx : 1,
        tx : 1,
        ux : 1,
        vx : 1,
        wx : 1,
        xx : 1,
        yx : 1,
        zx : 1,
        ay : 1,
        by : 1,
        cy : 1,
        dy : 1,
        ey : 1,
        fy : 1,
        gy : 1,
        hy : 1,
        iy : 1,
        jy : 1,
        ky : 1,
        ly : 1,
        my : 1,
        ny : 1,
        oy : 1,
        py : 1,
        qy : 1,
        ry : 1,
        sy : 1,
        ty : 1,
        uy : 1,
        vy : 1,
        wy : 1,
        xy : 1,
        yy : 1,
        zy : 1,
        az : 1,
        bz : 1,
        cz : 1,
        dz : 1,
        ez : 1,
        fz : 1,
        gz : 1,
        hz : 1,
        iz : 1,
        jz : 1,
        kz : 1,
        lz : 1,
        mz : 1,
        nz : 1,
        oz : 1,
        pz : 1,
        qz : 1,
        rz : 1,
        sz : 1,
        tz : 1,
        uz : 1,
        vz : 1,
        wz : 1,
        xz : 1,
        yz : 1,
        zz : 1,
    })
    // steps indicators
    const [firstLoad, setFirstLoad] = useState(true)
    const [firstPapaLoad, setFirstPapaLoad] = useState(true)
    const [finalParamsLoad, setFinalParamsLoad] = useState(false)
    const [insideCulced, setInsideCulced] = useState(false)
    const [cena, setCena] = useState(false)
    // data
    const [wrpSum, setWrpSum] = useState([0,0])
    const [elemParams, setElemParams] = useState(reloadElParams())
    const [listNomInside, setListNomInside] = useState([])
    // add nom in wrp
    const [addInNom, setAddInNom] = useState(0)
    const [addInCat, setAddInCat] = useState('-')
    const [addInActive, setAddInActive] = useState(false)

    // reload element parametrs
    function calCount(incData) {
        let tmpArr = props.element.count

        // have parents?
        if(props.paCount){
            tmpArr = tmpArr * props.paCount
        }

        if(props.element.type === 'nom'){
            // consider pagonazh
            if(incData.pagonazh > 0){
                tmpArr = tmpArr * incData.pagonazh
            }

            // consider nomenclature count rounding
            if(isSysDat.nomenclature[props.element.id].round){
                tmpArr = Math.ceil(tmpArr)
            }
        }
        return tmpArr
    }
    function reloadElParams(){
        let returnedParams = {...paramsVars}
        returnedParams['kol'] = props.element.count*1
        returnedParams['kolvo'] = 1
        returnedParams['pagonazh'] = 1
        returnedParams['allnom'] = 0

        if(props.element.type === 'nom'){
            // in begin we upload params from standard DB nomenclature
            let tmpElParams = Object.entries(isSysDat.nomenclature[props.element.id].params)
            tmpElParams.forEach((pa) => {
                if(pa[1].type === 'dig'){
                    returnedParams[pa[0]] = 0
                    let tmpDigLine = pa[1].data.split('__')
                    tmpDigLine.forEach((di) => {
                        let diName = di.split(':')
                        if(diName[0].replace(/\s+/g, '') === 'val'){
                            returnedParams[pa[0]] = diName[1].replace(/\s+/g, '')*1
                        }
                    })
                }else{
                    returnedParams[pa[0]] = 1
                }
            })

            // and check params what saved in order for this nomenclature
            if(Object.keys(props.element.params).length){
                Object.entries(props.element.params).forEach((pms)=>{
                    returnedParams[pms[0]] = pms[1]*1
                })
            }

            // pogonazh calculating
            let pagoRezult = isSysDat.nomenclature[props.element.id].pogonazh
            if(pagoRezult){
                returnedParams['pagonazh'] = evaluate(pagoRezult, returnedParams)
            }

            // count total count = Kolvo
            let tmpKolvo = calCount(returnedParams)
            returnedParams['kolvo'] = tmpKolvo

            // add insided nomenclature with empty prices
            if(isSysDat.nomenclature[props.element.id].inside){
                Object.keys(isSysDat.nomenclature[props.element.id].inside).forEach((nom) => {
                    returnedParams[nom] = 0
                    returnedParams['s' + nom] = 0
                })
            }

            return returnedParams
        }else{
            // count total count = Kolvo
            let tmpKolvo = calCount(returnedParams)
            returnedParams['kolvo'] = tmpKolvo

            return returnedParams
        }
    }
    function finalParams(){
        let returnedParams = {...elemParams}

        // add prices of insided nomenclature, because other params is ready
        Qs_('.element[data-pos="'+props.element.pos+'"] > .element-inside > .insided').forEach((ins) => {
            let nom = ins.getAttribute('data-var')
            returnedParams[nom] = ins.querySelector('.insided-summ').getAttribute('data-price0')*1
            returnedParams['s' + nom] = ins.querySelector('.insided-summ-in').getAttribute('data-price0')*1
            returnedParams['allnom'] += returnedParams['s' + nom]
        })

        return returnedParams
    }
    useEffect(() => {
        if(insideCulced){
            setElemParams(finalParams())
            setFinalParamsLoad(true)
        }
    },[insideCulced])
    useEffect(() => {
        setWrpSum([0,0])
        setFirstLoad(true)
        if(cena){
            setCena(false)
        }
        if(insideCulced){
            setInsideCulced(false)
        }
        if(finalParamsLoad){
            setFinalParamsLoad(false)
        }
        if(listNomInside.length > 0){
            setListNomInside([])
        }
        setElemParams(reloadElParams())
    },[props.changes, isPrices])

    // set inside noms list
    useEffect(()=>{
        if(firstLoad){
            if(props.element.type === 'nom' && isSysDat.nomenclature[props.element.id].inside){
                let listForReturn = []
                Object.entries(isSysDat.nomenclature[props.element.id].inside).forEach((nom) => {
                    let tmpEvResult = evaluate(nom[1].data, elemParams)
                    if(tmpEvResult){
                        let tmpNom = JSON.parse(JSON.stringify(nom[1]))
                        tmpNom['count'] = tmpEvResult
                        listForReturn.push(tmpNom)
                    }
                })
                setListNomInside(listForReturn)
            }else{
                // start calc sum
                setWrpSum(calcWrpSum(elemParams))
            }
            setFirstLoad(false)
        }
    },[elemParams])

    // calc price and summ
    function calcWrpSum(inData) {
        let tmpSum = [0,0]   // simple price & sum
        if(props.element.type === 'wrp'){
            if(insideCulced){
                Qs_('.element[data-pos="'+props.element.pos+'"] .element-inside > .element').forEach((ins) => {
                    tmpSum[0] += ins.querySelector('.element-summ-in').getAttribute('data-price0')*1 / props.element.count
                })
                tmpSum[1] = tmpSum[0] * props.element.count
                setTimeout(function () {
                    setCena(true)
                },100)
            }else{
                let intervalLimit = 1
                let intervalId1 = setInterval(function(){
                    intervalLimit++
                    let checker = 1

                    if(props.element.in.length > 0){
                        if(Qs_('.element[data-pos="'+props.element.pos+'"] > .element-inside > .element').length === 0){
                            checker = 0
                            if(Qs_('.element[data-pos="'+props.element.pos+'"]').length === 0){
                                intervalLimit = 99
                            }
                        }else{
                            Qs_('.element[data-pos="'+props.element.pos+'"] .element-inside > .element').forEach((ins) => {
                                if(ins.getAttribute('data-cena') === 'false'){
                                    checker = 0
                                }
                            })
                        }
                    }

                    if(intervalLimit === 99){
                        clearInterval(intervalId1)
                    }
                    if(checker === 1){
                        // close this interval
                        setInsideCulced(true)
                        clearInterval(intervalId1)
                    }
                }, 100);
            }
            return tmpSum
        }else if(props.element.type === 'nom'){
            let tmpPrice = isSysDat.nomenclature[props.element.id].price[isPrices*1 - 1]
            function allCulcedSetPrice(){
                if(inData.kolvo <= 0){
                    tmpSum = [0,0]
                }else{
                    tmpSum[0] = evaluate(tmpPrice, inData)
                    tmpSum[1] = tmpSum[0] * inData.kolvo
                }
                setTimeout(function () {
                    setCena(true)
                },100)
            }
            if(tmpPrice.includes("nom")){
                if(insideCulced){
                    allCulcedSetPrice()
                }else{
                    let intervalLimit = 1
                    let intervalId1 = setInterval(function(){
                        intervalLimit++
                        let checker = 1

                        if(listNomInside.length > 0){
                            if(Qs_('.element[data-pos="'+props.element.pos+'"] > .element-inside > .insided').length === 0){
                                checker = 0
                                if(Qs_('.element[data-pos="'+props.element.pos+'"]').length === 0){
                                    intervalLimit = 99
                                }
                                if(intervalLimit > 5){
                                    if(Qs_('.element[data-pos="'+props.element.pos+'"] > .element-inside > .one-hidden-nom').length === listNomInside.length){
                                        checker = 1
                                    }
                                }
                            }else{
                                Qs_('.element[data-pos="'+props.element.pos+'"] > .element-inside > .insided').forEach((ins) => {
                                    if(ins.getAttribute('data-cena') === 'false'){
                                        checker = 0
                                    }
                                })
                            }
                        }

                        if(intervalLimit === 99){
                            clearInterval(intervalId1)
                        }
                        if(checker === 1){
                            // close this interval
                            setInsideCulced(true)
                            clearInterval(intervalId1)
                        }
                    }, 100)
                }
            }else{
                allCulcedSetPrice()
            }
            return tmpSum
        }else if(props.element.type === 'uni'){
            tmpSum[0] = props.element.price[isPrices*1 - 1]
            tmpSum[1] = tmpSum[0] * inData.kolvo
            setTimeout(function () {
                setCena(true)
            },100)
            return tmpSum
        }
    }
    useEffect(()=>{
        if(!firstLoad){
            setWrpSum(calcWrpSum(elemParams))
        }
    },[listNomInside])
    useEffect(()=>{
        if(finalParamsLoad){
            setWrpSum(calcWrpSum(elemParams))
        }
    },[finalParamsLoad])

    // recalc everything when quantity of parent WRP is changed
    useEffect(() => {
        if(!firstPapaLoad){
            setFirstLoad(true)
            setElemParams(reloadElParams())
        }else{
            setFirstPapaLoad(false)
        }
    },[props.paCount])

    // Add element to order in WRP
    function addInElement(num) {
        props.setChanges(false)
        let tmpCod = '_'+Math.floor(Math.random() * 100000)
        let tmpObj = {}
        if(addInCat === 'uni'){
            tmpObj = {
                type: addInCat,
                id: addInCat + tmpCod,
                pos: addInCat + tmpCod,
                unit: 1,
                name: Q_('#add-in-input-name').value,
                price: [0, 0, 0],
                count: 1,
            }
        }else{
            tmpObj = {
                type: 'nom',
                id: addInNom / 1,
                pos: 'nom' + tmpCod,
                count: 1,
                params: {},
            }
        }

        let tmpOrder = []
        if(props.isContent.length > 0){
            tmpOrder = JSON.parse(JSON.stringify(props.isContent))
        }
        tmpOrder[num].in.push(tmpObj)
        props.setIsContent(tmpOrder)
        setAddInNom(0)
        setAddInCat('-')
        setAddInActive(false)
        setTimeout(function () {
            props.setChanges(true)
        }, 50)
    }

    // Edit price
    function editPrice(dataTarget) {
        Q_('.popup-newprice').style.display = 'grid'

        let parentPos = ''
        if(dataTarget.closest('.element-inside-wrp')){
            parentPos = dataTarget.closest('.element-inside-wrp').getAttribute('data-pos')
        }

        let newsumm = -1
        if(props.element.fixcost && props.element.fixcost[0] >= 0){
            if(props.element.fixcost[1] === 'one'){
                newsumm = elemParams.kolvo * props.element.fixcost[0]
            }
        }else if(props.element.newprice && props.element.newprice >= 0){
            newsumm = props.element.newprice
        }

        props.setPricePopData({
            cena : wrpSum[0],
            summ : wrpSum[1],
            newsumm : newsumm,
            count : elemParams.kolvo,
            self : props.element.pos,
            papa : parentPos,
        })
    }

    return (
        <div data-open="closed" data-cena={cena} data-pos={props.element.pos} data-id={props.element.id}
             data-kol={props.element.count} data-kolvo={elemParams.kolvo} data-deep={props.deep}
             className={`nom element ${'element-' + props.element.type} ${props.root ? 'element-root' : ''}`}>
            <div className="element-name" onClick={(e)=>{
                let parentPos, wrpIndex, nomIndex, nomCount = '';
                let nomParams = {};

                if(props.element.type === 'nom'){
                    if(e.target.closest('.element-inside')){
                        parentPos = e.target.closest('.element-inside').getAttribute('data-pos')
                        wrpIndex = props.isContent.findIndex(el => el.pos === parentPos)
                        nomIndex = props.isContent[wrpIndex].in.findIndex(el => el.pos === props.element.pos)
                        nomParams = props.isContent[wrpIndex].in[nomIndex].params
                        nomCount = props.isContent[wrpIndex].in[nomIndex].count
                    }else{
                        nomParams = props.element.params
                        nomCount = props.element.count
                        nomIndex = props.isContent.findIndex(el => el.pos === props.element.pos)
                    }

                    props.setNomPopParams({
                        type: 'nom',
                        id: props.element.id,
                        pos: props.element.pos,
                        index: nomIndex,
                        ppos: parentPos,
                        pindex: wrpIndex,
                        params: nomParams,
                        count: nomCount,
                        kolvo: elemParams.kolvo*1,
                    })
                }

                if(props.element.type === 'uni'){
                    if(e.target.closest('.element-inside')){
                        parentPos = e.target.closest('.element-inside').getAttribute('data-pos')
                        wrpIndex = props.isContent.findIndex(el => el.pos === parentPos)
                        nomIndex = props.isContent[wrpIndex].in.findIndex(el => el.pos === props.element.pos)
                        nomParams = {
                            'price' : props.isContent[wrpIndex].in[nomIndex].price,
                            'name' : props.isContent[wrpIndex].in[nomIndex].name,
                            'unit'   : props.isContent[wrpIndex].in[nomIndex].unit,
                        }
                        nomCount = props.isContent[wrpIndex].in[nomIndex].count
                    }else{
                        nomIndex = props.isContent.findIndex(el => el.pos === props.element.pos)
                        nomParams = {
                            'price' : props.element.price,
                            'name' : props.element.name,
                            'unit'   : props.element.unit,
                        }
                        nomCount = props.element.count
                    }

                    props.setNomPopParams({
                        type: 'uni',
                        id: props.element.id,
                        pos: props.element.pos,
                        index: nomIndex,
                        ppos: parentPos,
                        pindex: wrpIndex,
                        params: nomParams,
                        count: nomCount,
                        kolvo: elemParams.kolvo*1,
                    })
                }

                if(props.element.type === 'wrp'){
                    nomParams = {
                        'name' : props.element.name,
                        'unit'   : props.element.unit,
                    }
                    props.setNomPopParams({
                        type: 'wrp',
                        id: props.element.id,
                        pos: props.element.pos,
                        index: props.isContent.findIndex(el => el.pos === props.element.pos),
                        ppos: parentPos,
                        pindex: wrpIndex,
                        params: nomParams,
                        count: props.element.count,
                        kolvo: elemParams.kolvo*1,
                    })
                }

                Q_('.popup-nom').style.display = 'grid'
            }}>
                { props.element.type === 'nom' && listNomInside.length > 0
                    ?   <strong className="element-name-roll" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if(e.target.closest('.element').getAttribute('data-open') === 'closed'){
                            e.target.closest('.element').setAttribute('data-open', 'open')
                        }else{
                            e.target.closest('.element').setAttribute('data-open', 'closed')
                        }
                    }}><IcoArr/></strong>
                    :   ''
                }
                { props.element.type === 'wrp'
                    ?   <strong className="element-name-roll" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if(e.target.closest('.element').getAttribute('data-open') === 'closed'){
                                e.target.closest('.element').setAttribute('data-open', 'open')
                            }else{
                                e.target.closest('.element').setAttribute('data-open', 'closed')
                            }
                        }}><IcoArr/></strong>
                    :   ''
                }

                { props.element.type === 'nom'
                    ?   <p>
                            <span className="element-name-span">
                                {isSysDat.nomenclature[props.element.id].name}
                            </span>
                            { isSysDat.nomenclature[props.element.id].ca
                                ?   <span className="element-name-tire">-</span>
                                :   ''
                            }
                            { isSysDat.nomenclature[props.element.id].ca
                                ?   <span className="element-name-cat">{isSysDat.cats[isSysDat.nomenclature[props.element.id].ca]}</span>
                                :   ''
                            }
                        </p>
                    :   ''
                }
                { props.element.type === 'uni'
                    ?   <p>
                            <span className="element-name-span">{props.element.name}</span>
                            <span className="element-name-tire">-</span>
                            <span className="element-name-cat">Уникальная номенклатура</span>
                        </p>
                    :   ''
                }
                { props.element.type === 'wrp'
                    ?   <p>
                            <span className="element-name-span">
                                {props.element.name}
                            </span>
                            <span className="element-name-tire">-</span>
                            <span className="element-name-cat">Обёртка номенклатур</span>
                        </p>
                    :   ''
                }
            </div>
            <div className="element-count"
                 data-pcs={props.element.type === 'nom'
                    ?   isSysDat.nomenclature[props.element.id].unit
                    :   isSysDat.units[props.element.unit - 1].name
                 }
                 onClick={()=>{
                     if(props.element.type === 'nom'){
                         let tmpObj = {
                             id: props.element.id,
                         }
                         if(isSysDat.nomenclature[props.element.id].s){
                             isSysDat.nomenclature[props.element.id].s.split('_').forEach((st)=>{
                                 let tmpArr = st.split(':')
                                 tmpObj[tmpArr[0]] = tmpArr[1]
                             })
                             setPopStock(tmpObj)
                         }else{
                             setPopStock(tmpObj)
                         }
                         Q_('.popup-instock').style.display = 'grid'
                     }
                 }}
            >
                <span data-tooltip={props.element.type === 'nom'
                    ?   isSysDat.nomenclature[props.element.id].s
                        ?   isSysDat.nomenclature[props.element.id].s.split('_').map((ct)=>{
                                let tmpArr = ct.split(':')
                                if(isSysDat.companies[tmpArr[0]]){
                                    return isSysDat.companies[tmpArr[0]].nm.replace(/\s+/g, '_')  + '_____' + tmpArr[1] + '_____' + isSysDat.nomenclature[props.element.id].unit + ' '
                                }
                            })
                        :   'Этой номенклатуры НЕТ на складах'
                    :   'Нет привязки к складам'
                    }
                >
                    {parseFloat((elemParams.kolvo*1).toFixed(2))
                    }&#160;{ props.element.type === 'nom'
                        ?   isSysDat.nomenclature[props.element.id].unit
                        :   isSysDat.units[props.element.unit - 1].name
                    }
                </span>
                { props.element.type === 'nom' && isSysDat.nomenclature[props.element.id].round
                    ? <p className="element-count-alert" data-tooltip="Итоговое количество в данной номенклатуре автоматически округляется до большего целого числа">!</p>
                    : ''
                }
            </div>
            <div className={`element-price ${Lg_('auth-role-prices')*1 === 7 ? 'hideWhileCalc' : ''}`}>
                <span data-tooltip="Стоимость одной единицы этой номенклатуры"
                    className={
                        props.element.newprice && props.element.newprice >= 0 || props.element.fixcost && props.element.fixcost[0] >= 0
                            ? 'element-price-changed'
                            : ''
                    }
                    onClick={(e) => {editPrice(e.target)}}
                >
                    {props.element.fixcost && props.element.fixcost[0] >= 0
                        ?   parseFloat((props.element.fixcost[0] * 1).toFixed(2)).toLocaleString('ru-RU')
                        :   props.element.newprice && props.element.newprice >= 0
                            ?   elemParams.kolvo <= 0
                                ?   0
                                :   parseFloat((props.element.newprice / elemParams.kolvo).toFixed(2)).toLocaleString('ru-RU')
                            :   cena
                                ?   elemParams.kolvo <= 0
                                    ?   0
                                    :   parseFloat((wrpSum[1] / elemParams.kolvo).toFixed(2)).toLocaleString('ru-RU')
                                :   '----'
                    } р
                </span>
            </div>
            <div className={`element-summ ${Lg_('auth-role-prices')*1 === 7 ? 'hideWhileCalc' : ''}`}>
                <p className={`element-summ-wrp ${
                        props.element.newprice && props.element.newprice >= 0 || props.element.fixcost && props.element.fixcost[0] >= 0
                            ? 'element-summ-changed' 
                            : ''
                    }`}
                   data-tooltip="Итоговая стоимость всего количества этой номенклатуры"
                   onClick={(e) => {editPrice(e.target)}}
                >
                    <strong className="element-summ-in"
                            data-price0={
                                props.element.fixcost && props.element.fixcost[0] >= 0
                                    ?   elemParams.kolvo <= 0
                                        ?   0
                                        :   props.element.fixcost[0] * elemParams.kolvo
                                    :   props.element.newprice && props.element.newprice >= 0
                                        ?   props.element.newprice*1
                                        :   wrpSum[1]
                            }
                    >
                        {props.element.fixcost && props.element.fixcost[0] >= 0
                            ?   elemParams.kolvo <= 0
                                ?   0
                                :   parseFloat((props.element.fixcost[0] * elemParams.kolvo).toFixed(2)).toLocaleString('ru-RU')
                            :   props.element.newprice && props.element.newprice >= 0
                                ?   parseFloat((props.element.newprice*1).toFixed(2)).toLocaleString('ru-RU')
                                :   cena
                                    ?   parseFloat(wrpSum[1].toFixed(2)).toLocaleString('ru-RU')
                                    :   '----'
                        }
                    </strong>
                    <strong> р</strong>
                </p>
            </div>
            <div className="element-icons">
                <div className={`element-clone ${!sumReady || Lg_('auth-role-orders-5') > 0 && !props.order.parent_root || Lg_('auth-role-orders-6') > 0 && props.order.parent_root ? 'hideWhileCalc' : ''}`}
                     data-tooltip="Создать дубль" onClick={(e) =>{
                        props.setChanges(false)
                        let tmpContent = JSON.parse(JSON.stringify(props.isContent))
                        let tmpCod = '_'+Math.floor(Math.random() * 100000)
                        if(e.target.closest('.element-inside-wrp')){
                            let parentPos = e.target.closest('.element-inside-wrp').getAttribute('data-pos')
                            let wrpIndex = tmpContent.findIndex(el => el.pos === parentPos)
                            let nomIndex = tmpContent[wrpIndex].in.findIndex(el => el.pos === props.element.pos)
                            tmpContent[wrpIndex].in.splice(nomIndex, 0, props.element)
                            tmpContent[wrpIndex].in[nomIndex].pos = props.element.type + tmpCod
                        }else{
                            let nomIndex = tmpContent.findIndex(el => el.pos === props.element.pos)
                            tmpContent.splice(nomIndex, 0, props.element)
                            tmpContent[nomIndex].pos = props.element.type + tmpCod

                            // if it is wrp, then
                            if(tmpContent[nomIndex].in){
                                if(tmpContent[nomIndex].in.length > 0){
                                    tmpContent[nomIndex].in.forEach((ins) => {
                                        let tmpCod2 = '_'+Math.floor(Math.random() * 100000)
                                        ins.pos = ins.type + tmpCod2
                                    })
                                }
                            }
                        }
                        props.setIsContent(tmpContent)

                        setTimeout(function () {
                            props.setChanges(true)
                        }, 50)
                    }}
                ><IcoClone/></div>
                <div className={`element-delete ${!sumReady || Lg_('auth-role-orders-5') > 0 && !props.order.parent_root || Lg_('auth-role-orders-6') > 0 && props.order.parent_root ? 'hideWhileCalc' : ''}`}
                     onClick={(e) => {
                         props.setChanges(false)
                         let tmpContent = JSON.parse(JSON.stringify(props.isContent))
                         if(e.target.closest('.element-inside-wrp')){
                             let parentPos = e.target.closest('.element-inside-wrp').getAttribute('data-pos')
                             let wrpIndex = tmpContent.findIndex(el => el.pos === parentPos)
                             tmpContent[wrpIndex].in = tmpContent[wrpIndex].in.filter(el => el.pos !== props.element.pos)
                         }else{
                             tmpContent = tmpContent.filter(el => el.pos !== props.element.pos)
                         }
                         props.setIsContent(tmpContent)

                         setTimeout(function () {
                             props.setChanges(true)
                         }, 50)
                     }}
                     data-tooltip="Удалить"
                ><IcoTrash/></div>
            </div>
            <div className={`element-inside ${props.element.type === 'wrp' ? 'element-inside-wrp' : ''}`}
                 data-pos={props.element.pos}
            >
                { props.element.type === 'wrp' && props.element.in.length > 0
                    ?   props.element.in.map((el, i) => {
                        if(el.type === 'nom' && !isSysDat.nomenclature[el.id]){}else{
                            return(
                                <OneElement element={el} key={i} deep={1}
                                    setNomPopParams={props.setNomPopParams}
                                    isContent={props.isContent} setIsContent={props.setIsContent}
                                    changes={props.changes} setChanges={props.setChanges}
                                    paCount={elemParams.kolvo*1} setPricePopData={props.setPricePopData}
                                    order={props.order}

                                    rolled={props.rolled} setRolled={props.setRolled} rootpos={props.rootpos}
                                />
                            )
                        }
                    })
                    :   ''
                }
                { props.element.type === 'wrp'
                    ?   Lg_('auth-role-orders-5') > 0 && !props.order.parent_root || Lg_('auth-role-orders-6') > 0 && props.order.parent_root
                        ?   ''
                        :   addInActive
                            ?   <div className="param page-order-content-add inlink">
                                    <select className="page-order-content-add-incat" autoComplete="off" defaultValue="-" onChange={(e) => {
                                        setAddInCat(e.target.value)
                                        setAddInNom(0)
                                    }}>
                                        <option value="-" disabled="on">Выберите категорию</option>
                                        <option value="uni" >Уникальная номенклатура</option>
                                        {Object.entries(isSysDat.cats).map((el) => (
                                            <option value={el[0]} key={el[0]}>{el[1]}</option>
                                        ))}
                                        <option value="0">Без категории</option>
                                    </select>
                                    <div>
                                        { addInCat === '-'
                                            ?   ''
                                            :   addInCat === 'uni'
                                                ? <input id="add-in-input-name" type="text" autoComplete="off" placeholder="Введите название"/>
                                                : <select className="page-order-content-add-name" autoComplete="off" defaultValue="0" onChange={(e) => {
                                                    setAddInNom(e.target.value)
                                                }}>
                                                    <option value="0" data-id="0" disabled="on">Выберите номенклатуру</option>
                                                    {Object.keys(isSysDat.nomenclature).map((nomId, i) => {
                                                        if(isSysDat.nomenclature[nomId].ca == addInCat){
                                                            return (
                                                                <option value={isSysDat.nomenclature[nomId].id}
                                                                        data-cat={ isSysDat.nomenclature[nomId].ca
                                                                            ? isSysDat.nomenclature[nomId].ca
                                                                            : '0'
                                                                        } key={i}>
                                                                    {isSysDat.nomenclature[nomId].name}
                                                                </option>
                                                            )
                                                        }else if(addInCat == 0 && !isSysDat.nomenclature[nomId].ca){
                                                            return (
                                                                <option value={isSysDat.nomenclature[nomId].id} data-cat="0" key={i}>
                                                                    {isSysDat.nomenclature[nomId].name}
                                                                </option>
                                                            )
                                                        }
                                                    })}
                                                </select>
                                        }
                                    </div>
                                    <div>
                                        { addInCat !== '-'
                                            ?   addInCat === 'uni' || addInNom != 0
                                                ?   <div className={`page-order-content-add-btn ${!sumReady ? 'hideWhileCalc' : ''}`}
                                                         onClick={(e) => {
                                                             let parentPos = e.target.closest('.element-inside-wrp').getAttribute('data-pos')
                                                             let wrpIndex = props.isContent.findIndex(el => el.pos === parentPos)
                                                             addInElement(wrpIndex)
                                                         }}
                                                >Добавить</div>
                                                :   ''
                                            :   ''
                                        }
                                    </div>
                                    <div className="page-order-content-add-close" onClick={() => {
                                        setAddInNom(0)
                                        setAddInCat('-')
                                        setAddInActive(false)
                                    }}>×</div>
                                </div>
                            :   <p className="page-order-content-add-link inlink"
                                   onClick={() => setAddInActive(true)}
                                ><IcoPlus/> Добавить номенклатуру</p>
                    :   ''
                }

                {props.element.type === 'nom' && listNomInside.length > 0
                    ?   Object.entries(listNomInside).map((el, i) => {
                            if(isSysDat.nomenclature[el[1].id]){

                                // calc params
                                let sendOutParams = {}
                                if(el[1].data2){
                                    let outParams = el[1].data2.split('__')
                                    outParams.forEach((pa) => {
                                        let tmpPa = pa.split('~')
                                        let tmpEval = evaluate(tmpPa[1], elemParams)
                                        if(tmpEval){
                                            sendOutParams[tmpPa[0].replace(/\s+/g, '')] = tmpEval
                                        }
                                    })
                                }else{
                                    sendOutParams = false
                                }

                                // calc parent count
                                let sendCount = elemParams.kolvo*1;
                                if(isSysDat.nomenclature[props.element.id].nopapa){
                                    sendCount = 1;
                                }

                                // new price object
                                let pricesObj = ''
                                if(props.element.mod && props.element.mod[el[1].slug]){
                                    pricesObj = props.element.mod[el[1].slug]
                                }

                                // hide or show
                                if(pricesObj.hidden){
                                    return (
                                        <OneHiddenNom key={i} element={el} deep={props.deep + 1}
                                                setChanges={props.setChanges}
                                                isContent={props.isContent} setIsContent={props.setIsContent}
                                                order={props.order}
                                        />
                                    )
                                }else{
                                    return(
                                        <OneInsited key={i} element={el} deep={props.deep + 1}
                                                    setNomPopParams={props.setNomPopParams}
                                                    outParams={sendOutParams} newPrices={pricesObj}
                                                    changes={props.changes} setChanges={props.setChanges}
                                                    isContent={props.isContent} setIsContent={props.setIsContent}
                                                    paCount={sendCount} setPricePopData={props.setPricePopData}
                                                    order={props.order}

                                                    rolled={props.rolled} setRolled={props.setRolled} rootpos={props.rootpos}
                                        />
                                    )
                                }
                            }
                        })
                    :   ''
                }
            </div>
        </div>
    );
};

export default OneElement;