import React, {useContext, useEffect, useState} from 'react';
import Loadit from "../funcs/Load";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../context/Context";
import OneOrder from "../components/OneOrder";

const PgOrders = () => {
    // states and vars
    const navigate = useNavigate()
    const {Q_, Qs_, Lg_,
        isLandscape,

        isSysDat,
        setIsPage,
        orders, setOrders,
        isPrices, setIsPrices,

        setSumReady,
        ordersReload, setOrdersReload,

        isHideOldChecked, setIsHideOldChecked,
        filterCity, setFilterCity,
        filterClient, setFilterClient,
        filterPartner, setFilterPartner,
        filterWorker, setFilterWorker,

        openMessage,
    } = useContext(AuthContext)
    // indicators of steps
    const [firstPageLoad, setFirstPageLoad] = useState(true)
    // data
    const [isSortBy, setIsSortBy] = useState('id2')

    // ones for first load
    useEffect(() => {
        // set sumReady to TRUE when load this page
        setSumReady(true)

        // set current menu page
        setIsPage('orders')
    }, [])

    // sort again reloaded orders
    useEffect(()=>{
        if(orders.data){
            sortBy()
        }
    },[ordersReload, isSortBy, isPrices])

    // reload orders when toggle any filters
    async function toggleFilters() {
        // get and set
        let response = await Loadit.getOrders('two', 100, '', filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        setOrders(response)

        // show / hide more
        let elMore = Q_('.page-orders-more')
        if(response.count < 100){
            if(elMore){
                elMore.classList.add('page-orders-more-hide')
            }
            setTimeout(function () {
                if(Q_('.page-orders-more')){
                    Q_('.page-orders-more').classList.remove('page-orders-more-hide')
                }
            },60000)
        }else{
            if(elMore){
                elMore.classList.remove('page-orders-more-hide')
            }
        }

        // select filters if we come from PARTNERS or CLIENTS
        Q_('#orders-partner').value = filterPartner
        Q_('#orders-client').value = filterClient

        setOrdersReload(!ordersReload)
        Q_('.popup-wait').style.display = 'none'
    }
    useEffect(() => {
        if(firstPageLoad){
            setFirstPageLoad(false)
        }else{
            toggleFilters()
        }
    },[isHideOldChecked, filterCity, filterClient, filterPartner, filterWorker])

    // sorting of orders
    function sortBy() {
        let finalObject = JSON.parse(JSON.stringify(orders))
        let tmpList = finalObject.data

        if(isSortBy === 'id'){tmpList.sort(function(a, b){return a.id-b.id});}
        if(isSortBy === 'id2'){tmpList.sort(function(a, b){return b.id-a.id});}
        if(isSortBy === 'price'){tmpList.sort(function(a, b){return a.pc[isPrices-1]-b.pc[isPrices-1]});}
        if(isSortBy === 'price2'){tmpList.sort(function(a, b){return b.pc[isPrices-1]-a.pc[isPrices-1]});}

        setOrders(finalObject)
    }
    // searching in orders
    async function searchBy(str) {
        let searchLine = ' '+str
        searchLine = searchLine.replace(':', '')
        searchLine = searchLine.replace('ID', '')
        searchLine = searchLine.replace('id', '')
        searchLine = searchLine.replace(/\s+/g, '')

        let respoZ = await Loadit.checkOrder(searchLine)
        if(respoZ && respoZ > 0){
            navigate('/orders/'+searchLine, {replace: true})
        }else{
            openMessage('Упс!', 'Заказа с таким ID не найдено...')
        }

        Q_('.popup-wait').style.display = 'none'
    }
    // load more orders
    async function getMoreOrders() {
        Q_('.popup-wait').style.display = 'grid'
        let finalObject = JSON.parse(JSON.stringify(orders))

        let countOfHaving = Qs_('.page-orders-wrp .one-order').length
        let offsetOfLoad = ''
        if(!countOfHaving){
            countOfHaving = 100
        }else{
            if(countOfHaving < 100){
                offsetOfLoad = countOfHaving - 10
                if(offsetOfLoad < 1){
                    offsetOfLoad = ''
                }
                countOfHaving = 100
            }else{
                offsetOfLoad = countOfHaving - 10
            }
        }

        let respo = await Loadit.getOrders('two', 100, offsetOfLoad, filterCity, isHideOldChecked, filterClient, filterPartner, filterWorker)
        respo.data.forEach((ord)=>{
            let tmpIndex = finalObject.data.findIndex(el => el.id === ord.id)
            if(tmpIndex === -1){
                finalObject.data.push(ord)
            }
        })

        setOrders(finalObject)
        setOrdersReload(!ordersReload)

        if(respo.count < 100){
            Q_('.page-orders-more').classList.add('page-orders-more-hide')
            setTimeout(function () {
                if(Q_('.page-orders-more')){
                    Q_('.page-orders-more').classList.remove('page-orders-more-hide')
                }
            },60000)
        }
        Q_('.popup-wait').style.display = 'none';
    }

    return (
        <div className="page page-orders">
            { isSysDat.st
                ?   <div className="page-orders-box">
                        <div className="page-orders-head">
                            <div className={`filter-search-wrp ${Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 7
                                ? 'filter-search-wrp-twocol' : ''}`}>
                                <input className="filter filter-search" type="text" placeholder="Поиск заказа по ID"
                                       onKeyPress={(e) => {
                                           if(e.key === 'Enter'){
                                               searchBy(e.target.value)
                                           }
                                       }}
                                />
                                <div className="filter-search-ico" onClick={()=>searchBy(Q_('.input-search').value)}> </div>
                            </div>
                            <div className={`page-orders-head-wrp page-orders-head-prices ${Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 7
                                ? 'hideWhile' : ''}`}>
                                <select autoComplete="off" className="filter" defaultValue={isPrices}
                                        onChange={(e) => setIsPrices(e.target.value)}
                                >
                                    { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 1 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 4 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 5
                                        ?   <option value="1">Закупочные цены</option>
                                        :   ''
                                    }
                                    { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 2 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 4 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 6
                                        ?   <option value="2">Партнёрские цены</option>
                                        :   ''
                                    }
                                    { !Lg_('auth-role-prices') || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 0 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 3 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 5 || Lg_('auth-role-prices') && Lg_('auth-role-prices')*1 === 6
                                        ?   <option value="3">Розничные цены</option>
                                        :   ''
                                    }
                                </select>
                            </div>
                            <div className="page-orders-head-sortby page-orders-head-wrp"
                                 data-tooltip="Сортировка ПОКАЧТО работает только среди подгруженных заказов! Кнопка подгрузки внизу под списком заказов."
                            >
                                <select id="orders-sortby" className={`filter ${isSortBy !== 'id2' ? 'active' : ''}`} autoComplete="off" defaultValue={isSortBy}
                                        onChange={(ev) => setIsSortBy(ev.target.value)}>
                                    <option value="id">Сортировать по Дате ↑</option>
                                    <option value="id2">Сортировать по Дате ↓</option>
                                    <option value="price">Сортировать по Цене ↑</option>
                                    <option value="price2">Сортировать по Цене ↓</option>
                                </select>
                            </div>
                            <div className="page-orders-head-wrp page-orders-head-create">
                                <div className={`create ${Lg_('auth-role-orders-1') > 0 ? 'hideWhileCalc' : ''}`}
                                     onClick={() => navigate(`/orders/new`, { replace: true })}
                                >{isLandscape ? 'Создать заказ' : <span>+</span>}
                                    <div className="wd">{Lg_('auth-name')} {Lg_('auth-surname')}</div>
                                </div>
                            </div>
                            <select id="orders-step" autoComplete="off" defaultValue={isHideOldChecked}
                                    className={`filter ${isHideOldChecked ? 'active' : ''}`}
                                    onChange={(e) => {
                                        Q_('.popup-wait').style.display = 'grid'
                                        setIsHideOldChecked(e.target.value)
                                    }}
                            >
                                <option value="">Фильтр по Стадии</option>
                                {Object.entries(isSysDat.st).map((el) => (
                                    <option value={el[0]} key={el[0]}>{el[1]}</option>
                                ))}
                            </select>
                            <div className="page-orders-head-wrp page-orders-head-client">
                                <select autoComplete="off" defaultValue={filterClient} className={`filter ${filterClient ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterClient(e.target.value)
                                        }}
                                        id="orders-client"
                                >
                                    <option value="">Фильтр по Клиенту</option>
                                    {Object.entries(isSysDat.companies).filter(el => el[1].osn[0] === true).sort(function(a, b){return b[1].id-a[1].id}).map((el, i) => {
                                        if(el[1].id != 3059){
                                            return(
                                                <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                            )
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="page-orders-head-wrp page-orders-head-partner">
                                <select autoComplete="off" defaultValue={filterPartner} className={`filter ${filterPartner ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterPartner(e.target.value)
                                        }}
                                        id="orders-partner"
                                >
                                    <option value="">Фильтр по Исполнителю</option>
                                    {Object.entries(isSysDat.companies).filter(el => el[1].osn[1] === true).map((el) => (
                                        <option value={el[1].id} key={el[1].id}>{el[1].nm} (id:{el[1].id})</option>
                                    ))}
                                </select>
                            </div>
                            <div className="page-orders-head-wrp page-orders-head-worker">
                                <select autoComplete="off" defaultValue={filterWorker} className={`filter ${filterWorker ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterWorker(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Ответственному</option>
                                    { isSysDat.users.map((el) => (
                                        <option value={el.value} key={el.value}>{el.role}: {el.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="page-orders-head-wrp page-orders-head-step">
                                <select id="orders-city" autoComplete="off" defaultValue={filterCity}
                                        className={`filter ${ Lg_('auth-role-cities')*1 === 1 ? 'hideWhileCalc' : ''} ${filterCity ? 'active' : ''}`}
                                        onChange={(e) => {
                                            Q_('.popup-wait').style.display = 'grid'
                                            setFilterCity(e.target.value)
                                        }}
                                >
                                    <option value="">Фильтр по Городу</option>
                                    {Object.entries(isSysDat.cities).map((el) => (
                                        <option value={el[0]} key={el[0]}>{el[1]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="page-orders-wrp">
                            {orders.count || orders.count === 0
                                ?   orders.count !== 0
                                    ?   orders.data.map((el, i) => (
                                            <OneOrder thisOrder={el} key={i}/>
                                        ))
                                    :   <h6>Заказов с такими параметрами не найдено<span>Либо у вас нет доступа к их просмотру</span></h6>
                                :   <h6>Загрузка заказов, ожидайте...<span>Если очень долго грузится, то нажмите обновить страницу</span></h6>
                            }
                        </div>
                        {orders.count
                            ?   orders.count !== 0
                                ?   <div className="page-orders-more" onClick={()=>getMoreOrders()}>Подгрузить ещё заказы из истории</div>
                                :   ''
                            :   ''
                        }
                    </div>
                :   <h6>
                        Загрузка номенклатур и системных данных, ожидайте...
                        <span>Если очень долго грузится, то нажмите обновить страницу</span>
                    </h6>
            }
        </div>
    );
};

export default PgOrders;